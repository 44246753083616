.profileBioWrapper {

}

.profileBio {
  display: flex;
  margin-bottom: 2rem;
  padding-top: 1rem;
}

.mainTitle {
  font-size: 2.5rem;
  // margin-bottom: 1rem;
}

.titleWrapper {
  width: 100%;
}

.titleContainer {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;

  h1 {
    margin-right: auto;
  }
}

.bio {
  font-size: 1rem;
  padding-top: 0.5rem;
  color: var(--primary-font-rgb);

  p {
    line-height: 1.55rem;
  }
}

.linkWrapper {
  display: flex;
  flex-wrap: wrap;
  padding-top: 0.5rem;
  color: var(--secondary-font-rgb);
  font-weight: bold;

  .linkIcon {
    // rotate the icon
    transform: rotate(-45deg);
  }

  a {
    margin-top: 0.15rem;
    margin-left: 0.5rem;
  }
}

.chipWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.75rem;
}

.avatarWrapper {
  margin-right: 1.5rem;
  padding-top: 2rem;
}

.avatarImage {
  width: 125px;
  height: 125px;
}

.pushRight {
  margin-left: auto;
}

.pushDown {
  // padding-top: 5px;
}

.infoWrapper {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 0.5rem;
  // border-bottom: 1px solid var(--faint-border-color);
}

.infoWrapperBottom {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1.3rem;
  border-top: 1px solid var(--faint-border-color);
}

.infoButton {
  margin-right: 1rem;
  font-size: 1.25rem;
  font-weight: 100;
}

.infoButtonNumber {
  font-weight: bold;
}

.mobileBottom {
  display: none;
}

@media screen and (max-width: 450px) {
  .mobileBottom {
    display: block;

    .bio {
      margin-bottom: 1rem;
    }

    .profileBio {
      padding-top: 0.5rem;
      margin-bottom: 0.25rem;
    }

    .bio:nth-child(1) {
      padding-top: 0.25rem;
    }
  }

  .titleContainer {
    flex-wrap: wrap;
    margin-bottom: 0px;

    .h1Wrapper {
      width: 100%;
    }

    h1 {
      width: 100%;
      margin-bottom: 0.5rem;
    }
    .mainTitle {
      width: 100%;


    }
  }

  .pushRight {
    margin-left: 0;
  }

  .avatarWrapper {
    padding-top: 0.5rem;
  }

  .avatarImage {
    width: 75px !important;
    height: 75px !important;
  }

  .infoWrapper {
    display: none;
  }
  .infoWrapperBottom {
    display: flex;

    .infoButton:nth-child(1) {
      margin-left: auto;
    }
    .infoButton:last-child {
      margin-right: auto;
    }
  }

  .bio {
    padding-top: 0px;
    margin-bottom: 0px;
  }

  .profileBio {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0.60rem;

    .bio {
      display: none;
    }
  }
}
