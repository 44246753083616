.wrapper {
  max-width: 800px;
  min-height: 40vh;
  margin-left: auto;
  margin-right: auto;
  font-family: var(--font-roboto);
  margin-top: 4rem;

  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
}

.loader {
  padding-top: 20vh;
}

.table {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  border-left: 1px solid var(--faint-border-color);
  border-right: 1px solid var(--faint-border-color);
}

.row {
  // display: flex;
  text-align: left;
}

.col {
  padding: 1rem;
  line-height: 1.7rem;
  border-bottom: 1px solid var(--faint-border-color);
}

.buttonCol {
  min-width: 150px;
}

.pagination {
  padding-top: 1rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.link {
  color: var(--secondary-color);
  transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-out;

  &:hover {
    opacity: 0.7;
  }
}
