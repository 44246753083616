.wrapper {
  width: 600px;
  margin: 0 auto;
  margin-top: 2em;
  max-width: 100vw;

  h1 {
    font-size: 2em;
    margin-bottom: 1em;
  }

  p {
    margin-bottom: 1em;
  }
}

.input {
  margin-bottom: 0.5em;
}

.buttonWrapper {
  width: 100%;
  text-align: right;
}
.button {
  margin-left: auto;
}

@media screen and (max-width: 450px) {
  .wrapper {
    padding: 1rem;
    margin: 0;
  }
}
