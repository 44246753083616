.wrapper {
  background: #FFF;
  margin-left: auto;
  margin-right: auto;
  padding: 16px;
  border-radius: 6px;
  margin-top: 24px;
  color: var(--primary-font-rgb);
  font-family: var(--font-mono);
}

.breadCrumb {
  display: flex;
}

.anchor {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2px;
  text-orientation: sideways-right;
  text-transform: uppercase;
  margin-right: 8px;
}

.lastAnchor {
  opacity: 0.8;
  color: #c68e3e;
  font-style: italic;
}

.anchor:hover {
  color: #c68e3e;
}

.split {
  font-size: 16px;
  margin-right: 8px;
}

@media screen and (max-width: 450px) {
  .wrapper {
    width: 100%;
    padding: 0px;
  }
  .anchor {
    font-size: 14px;
  }

  .split {
    margin-top: 2px;
    font-size: 14px;
  }
}
