.wrapper {
  background: var(--background-color);
  font-family: var(--font-roboto);
  margin-top: 80px;
}

.innerWrapper {
  padding: 2rem;
  background: var(--background-color);
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh;
  width: 100%;
}

.breadCrumb {
  padding-bottom: 1.5rem;
  // background: red;
}

.buttonWrapper {
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--faint-border-color);
  padding-left: 17px;
  padding-right: 17px;
  display: flex;
  flex-wrap: wrap;
}

.chooseTopicsButton {
  margin-left: auto;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 0.7; }
}

@keyframes fadeOut {
  0% { opacity: 0.7; }
  50% { opacity: 0.7; }
  100% { opacity: 0; }
}

.saveStatus {
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 17px;
  font-size: 0.9rem;
  opacity: 0.7;
  font-family: var(--font-mono);
}

.saveStatus.fadeIn {
  opacity: 0;
  animation: fadeIn 2s;
}

.doneStatus {
  opacity: 0;
  animation: fadeOut 1.25s;
}

.thumbnailBar {
  margin-bottom: 1.25rem;
  display: flex;
  flex-wrap: wrap;
}

.thumbnail {
  // width: auto;
  // height: 200px;
  // background: #F1F1F1;
  // border-radius: 3px;
  // text-align: center;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // add space betwen images
  margin-right: 0.5rem;
  margin-bottom: 0.25rem;
  position: relative;

  img {
    width: auto !important;
    height: 200px !important;
    border-radius: 3px;
  }

  .buttonBar {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
  }
}

.button {
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.hoveredThumbnail {
  background: #FFF;

  img {
    opacity: 0.2;
  }
}

.thumbnailText {
  font-size: 0.9rem;
  color: #FFF;
  cursor: pointer;
  padding: 10px;
  background: var(--primary-font-rgb);
  width: 200px;
  text-align: center;
  border-radius: 3px;

  &:hover {
    color: var(--secondary-font-rgb);
  }
}

.articleImageWrapper {
  margin-bottom: 2rem;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.articleImage {
  // width: auto;
  // height: auto;
  display: block;
}

.articleImageDescription {
  margin-top: 0.5rem;
  font-size: 0.8rem;
  color: var(--primary-font-rgb);
  text-align: center;
  font-style: italic;
}

.featuredImageButton {
  margin-top: 1rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.minRequirements {
  font-size: 0.8rem;
  color: var(--primary-font-rgb);
  margin-top: 0.5rem;
  // margin-bottom: 1rem;
  opacity: 0.7;

  div:first-child {
    margin-top: 1rem;
  }
  div {
    margin-bottom: 0.5rem;
  }
}

.featuredImageWrapper {
  // text-align: center;
  padding: 2rem;
  border: 1px dashed var(--primary-font-rgb);
  border-radius: 3px;
}

.addMoreTags {
  font-size: 0.8rem;
  color: var(--secondary-font-rgb);
  margin-top: 0.8rem;
  cursor: pointer;
  padding-left: 3px;
  text-align: right;
}

.chipStack {
  margin-top: 1rem;
}

.editorWrapper {
  background: var(--input-background);
  padding: 1rem;
  border-radius: 3px;
}

@media screen and (max-width: 450px) {
  .innerWrapper {
    padding: 24px 10px;
    max-width: 450px;
  }

  .buttonWrapper {
    padding-left: 0px;
    padding-right: 0px;
  }

  .editorWrapper {
    max-width: calc(450px - 20px);
    overflow-y: scroll;
  }
}
