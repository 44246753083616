.wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4rem;
  margin-bottom: 4rem;
  // border: 1px solid black;

  * {
    // border: 1px solid black;
  }
}

.title {
  font-size: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  max-width: 700px;

  h2 {
    margin-bottom: 1rem;
    font-size: 3rem;
  }
}

.section {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.video {
  margin-left: auto;
}

.videoStyle {
  width: 400px;
  height: 400px;
}

.link {
  font-size: 1.25rem;
  font-weight: 100;
  color: var(--secondary-font-rgb);
  text-decoration: none;
  position: relative;
  transition: all 0.25s ease-in-out;
  margin-bottom: 2rem;
  margin-top: 1rem;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px; // Adjust the thickness of the underline
    bottom: 0;
    left: 50%;
    background-color: currentColor; // Use the text color for the underline
    transition: all 0.25s ease-in-out;
  }

  &:hover::after {
    width: 100%;
    left: 0;
  }
}

@media screen and (max-width: 1195px) {
  .wrapper {
    max-width: 100%;
  }

  .title {
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .video {
    margin-left: auto;
    margin-right: auto;
  }

  .videoStyle {
    width: 500px;
    height: 500px;
  }
}


@media screen and (max-width: 750px) {
  .wrapper {
    max-width: 100%;
    padding: 0 1rem;
  }

  .title {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  .video {
    margin-left: auto;
    margin-right: auto;
  }

  .videoStyle {
    width: 350px;
    height: 350px;
  }
}


@media screen and (max-width: 400px) {
  .videoStyle {
    width: 300px;
    height: 300px;
  }
}
