.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 100px 1rem;
  text-align: center;
  background: #313131;
  color: #FFF;

  h3 {
    font-size: 2.5rem;
    font-weight: 100;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.5rem;
    font-weight: 100;
    margin-bottom: 20px;
  }
}

.link {
  font-size: 1.5rem;
  font-weight: 100;
  color: var(--secondary-font-rgb);
  text-decoration: none;
  position: relative;
  transition: all 0.25s ease-in-out;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px; // Adjust the thickness of the underline
    bottom: 0;
    left: 50%;
    background-color: currentColor; // Use the text color for the underline
    transition: all 0.25s ease-in-out;
  }

  &:hover::after {
    width: 100%;
    left: 0;
  }
}
