.glitterWrapper {
  position: relative;
  transition: all 0.5s;

  &:hover {
    background: var(--primary-chip-bg);
    border-radius: 3px;
  }
}

.playerWrapper {
  position: absolute;
  top: 5px;
  right: -20px;
  z-index: 1;
  text-align: center;
  width: 100%;
}

.playerWrapper2 {
  position: absolute;
  top: -20px;
  left: -27px;
  z-index: 2;
  text-align: center;
  width: 100%;
}

.player {
  transform: rotate(-20deg);
  height: 50px;
  margin-left: 30px;
}

.player2 {
  transform: rotate(160deg);
  height: 50px;
  margin-left: 30px;
}
