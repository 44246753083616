.wrapper {
  font-family: var(--font-mono);
  color: var(--primary-font-rgb);
  margin-left: auto;
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.wrap {
  display: flex;
  background: #FFF;
  height: 100%;
}

.leftSection {
  display: flex;
  padding: 40px 16px;
  position: fixed;
  height: 100vh;
  width: 44px;
  writing-mode: vertical-lr;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2px;
  text-orientation: sideways-right;
  text-transform: uppercase;
  transform: rotate(180deg);
  margin-top: auto;
  margin-bottom: auto;
  padding-bottom: 24px;
}

.rightSection {
  margin-left: 100px;
  padding-top: 60px;
  width: calc(100% - 44px);
}

.breadCrumb {
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.anchor {
  /* color: var(--primary-font-rgb); */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2px;
  text-orientation: sideways-right;
  text-transform: uppercase;
  margin-right: 8px;
}

.anchor:hover {
  color: #c68e3e;
}

.breadCrumb > .split {
  font-size: 16px;
  margin-right: 8px;
}

@media screen and (max-width: 1400px) {
  .wrapper {
    height: 140vh;
  }
}

@media screen and (max-width: 1000px) {
  .leftSection {
    display: none;
  }

  .rightSection {
    /* margin: 24px; */
    margin: 0px;
    padding: 24px;
    width: 100%;
  }
}

@media screen and (max-width: 490px) {
  .wrapper {
    height: 150vh;
  }

  .rightSection {
    padding: 16px;
    margin: 0px;
    width: 100%;
  }
}
