.whatIsSteppingStones {
  margin-top: 4rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  h2 {
    margin-bottom: 1.5rem;
    font-size: 3rem;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  p {
    max-width: 700px;
    font-size: 1.5rem;
    // margin-bottom: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    // text-align: center;
  }
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  background: #313131;
  opacity: 0.25;

  img {
    width: 1800px !important;
    height: auto !important;
    overflow: hidden;
  }
}

.topWrapper {
  position: relative;
  z-index: 2;
  padding: 4rem;
}

.createAccountWrapper {
  text-align: center;
  max-width: 210px;
  margin-left: auto;
  margin-right: auto;
}

.cardWrapper {
  text-align: center;
  display: flex;
  gap: 1rem;
  overflow-x: scroll;
  margin-left: auto;
  margin-right: auto;
}

.link {
  display: flex;
  font-size: 1.25rem;
  font-weight: 100;
  color: var(--secondary-font-rgb);
  text-decoration: none;
  position: relative;
  transition: all 0.25s ease-in-out;
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 1rem;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px; // Adjust the thickness of the underline
    bottom: 0;
    left: 50%;
    background-color: currentColor; // Use the text color for the underline
    transition: all 0.25s ease-in-out;
  }

  &:hover::after {
    width: 100%;
    left: 0;
  }
}

.itemImage {
  margin-left: auto;
  margin-right: auto;
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 3rem;
}

.largeImage {
  width: 210px;
  height: 210px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.card {
  min-width: 345px;
  max-width: 345px;
}

@media screen and (max-width: 500px) {
  .whatIsSteppingStones {
    margin-top: 2rem;
  }

  .topWrapper {
    padding: 4rem 1rem;
  }

  .card {
    min-width: 300px;
    max-width: 300px;
  }
}
