.footer {
  background: var(--primary-color);
  color: var(--background-color);
  padding: 24px;
  padding-bottom: 80px;
  font-family: var(--font-mono);
}

html[data-theme='dark'] {
  .footer {
    background: #313131;
    color: #FFF;
  }
}

.innerFooter {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  max-width: calc(var(--max-width) - 400px);
}

.columnOne {
  flex: 1;
  padding: 20px;
  max-width: 600px;
  min-width: 350px;
}

.upperCell {
  margin-bottom: 48px;
}

.lowerCell {
  font-size: 0.8rem;
  text-align: center;
  opacity: 0.3;
}

.columnTwo {
  flex: 1;
  padding: 20px;
  max-width: 700px;
  min-width: 350px;
}

.linkWrapper {
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  margin-bottom: 24px;
}

.link {
  padding: 2.5px;
  margin-bottom: 10px;
  margin-right: 10px;
  font-size: 0.8rem;
  /* animation 2.5s all */
  transition: 0.2s all;
}

.link:hover {
  color: var(--default-color);
  text-decoration: underline;
}

.footerLogo {
  width: calc(422.1252px);
  height: calc(166.9062px);
}

.socialMediaBarContainer {
  margin-top: 24px;
  max-width: 200px;
}

.columnFooter {
  font-family: var(--font-roboto);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 1.4rem;

  .column {
    margin-right: 1.5rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;

    h5 {
      font-size: 1rem;
      margin-bottom: 0.5rem;
      font-weight: bold;
    }

    a {
      margin-bottom: 0.5rem;
      opacity: 0.7;
    }
  }
}

@media screen and (max-width: 450px) {
  .footerLogo {
    width: calc(422.1252px * 0.7);
    height: calc(166.9062px * 0.7);
  }

  .columnOne {
    padding: 0px;
  }

  .upperCell {
    margin-bottom: 24px;
  }

  .columnTwo {
    padding: 0px;
    margin-bottom: 24px;
  }

  .link {
    padding: 0px;
  }

  .socialMediaBarContainer {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 850px) {
  .innerFooter {
    display: block;
  }

  .upperCell {
    margin-left: auto;
    margin-right: auto;
    width: calc(422.1252px);
    margin-bottom: 0px;
  }

  .columnOne {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 300px;
  }

  .columnTwo {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    // text-align: center;
    margin-bottom: 24px;
  }

  .lowerCell {
    text-align: center;
  }

  .socialMediaBarContainer {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 1000px) {
  .footer {
    padding: 40px;
  }
}

@media screen and (max-width: 1600px) {
  .innerFooter {
    max-width: calc(var(--max-width) - 800px);
  }
}
