.wrapper {
  // font-family: var(--font-roboto);
  font-size: 2rem;
  background: #FFF;
  color: #313131;
}

.banner {
  margin-top: 4rem;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;

  h1 {
    margin-bottom: 1.5rem;
    font-size: 3rem;
  }
}

.leftBanner {
  margin-right: 8rem;
}

.rightBanner {
  padding-top: 4rem;
  max-width: 700px;
}

.bannerVideo {
  width: 500px;
  height: 500px;
}

.bannerListWrapper {
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0.5rem 0rem 1.5rem;
  padding: 0;
  list-style-type: none;
}

.bannerListRow {
  margin-bottom: 0.5rem;
  background-color: rgb(253, 251, 224);
  display: flex;
  padding: 0px 3px;
  svg {
    margin-right: 1rem;
  }
}

.bannerActions {
  display: flex;
  margin-top: 1.5rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  button {
    margin-right: 0.5rem;
    min-width: 200px;
    border-radius: 100px;
  }
}

.iconStyle {
  font-size: 2rem;
}

@media screen and (max-width: 1328px) {
  .banner {
    max-width: 100%;
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .leftBanner {
    margin-right: 0px;
    margin-left: auto;
    margin-right: 1rem;
  }

  .rightBanner {
    margin-right: auto;
  }

  .bannerVideo {
    width: 400px;
    height: 400px;
  }
}

@media screen and (max-width: 1120px) {
  .wrapper {
    padding: 4rem 2rem;
  }

  .banner {
    max-width: 100%;
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  // switch .leftBanner and .rightBanner
  .leftBanner {
    order: 2;
    margin-right: auto;
    margin-left: auto;
  }

  .rightBanner {
    order: 1;
    margin-top: 0rem;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 820px) {
  .leftBanner {
    order: 2;
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (max-width: 820px) {
  .leftBanner {
    order: 2;
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (max-width: 750px) {
  .rightBanner {
    max-width: 500px;

    h1 {
      font-size: 2rem;
    }
  }

  .bannerListWrapper {
    font-size: 1.25rem;
  }

  .iconStyle {
    font-size: 1.5rem;
  }

  .bannerVideo {
    width: 300px;
    height: 300px;
  }
}

@media screen and (max-width: 520px) {
  .rightBanner {
    max-width: 450px;

    h1 {
      font-size: 2rem;
    }
  }

  .bannerListWrapper {
    font-size: 1.25rem;
  }

  .iconStyle {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 470px) {
  .rightBanner {
    max-width: 100%;
    padding: 0 1rem;

    h1 {
      font-size: 2rem;
    }
  }

  .bannerListWrapper {
    font-size: 1.25rem;
  }

  .iconStyle {
    font-size: 1.5rem;
  }

  .bannerActions {
    display: flex;
    flex-direction: column;

    button {
      margin-bottom: 16px;
      width: 100%;
      border-radius: 3px;
    }
  }
}

@media screen and (max-width: 400px) {
  .wrapper {
    max-width: 100%;
    padding-top: 2rem;
  }

  .rightBanner {
    max-width: 100%;

    h1 {
      font-size: 2.5rem;
    }
  }
}
