.wrapper {
  background: var(--background-color);
  padding: 1rem;
  margin-bottom: 10rem;
}

.editorWrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  background: var(--translation-editor-bg);
  // display: flex;
  min-height: 75vh;
  border: 1px solid var(--faint-border-color);
  border-radius: 2px;
}

.sourceLanguage {
  // width: calc(50% - 4px);
  // border-right: 8px solid var(--faint-border-color);
  // fill entire height of parent
  // display: flex;
  // flex-direction: column;
  // padding: 1rem;
  border: 8px solid var(--faint-border-color);

  img {
    max-width: 100%;
    max-height: 100%;
  }

  h2 {
    margin-bottom: 2rem;
  }
}

.sourceSection {
  display: flex;
  border-bottom: 8px solid var(--faint-border-color);
  transition: background 0.2s;
  // border: 1px solid yellow;

  h1 {
    font-size: 2rem;
    font-family: var(--font-system);
  }
}

.leftSection {
  // width: calc(50% - 4px);
  // display: flex;
  // flex-direction: column;
  // padding: 1rem;
  // width: calc(50% - 4px);
  border-right: 8px solid var(--faint-border-color);
  width: 50%;
  padding: 1rem;
}

.titleLeftSection {
  padding-top: 3.25rem;
  font-family: var(--font-system);
}

.rightSection {
  // width: calc(50% - 4px);
  // border-right: 8px solid var(--faint-border-color);
  width: 50%;
  padding: 1rem;
  font-family: var(--font-system);
}

@keyframes fadeIn {
  from {
    padding-top: 1rem;
  }
  to {
    padding-top: 4.54rem;
  }
}

@keyframes fadeOut {
  from {
    padding-top: 4.54rem;
  }
  to {
    padding-top: 1rem;
  }
}

.editorIsEnabled {
  animation: fadeIn 0.5s forwards;
}

.editorIsDisabled {
  animation: fadeOut 0.5s forwards;
}

.editorBottom {
  width: 100%;
  border-top: 2px solid var(--faint-border-color);
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  background: var(--hover-menu-bg);
  border-radius: 3px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 3rem;
  align-items: center;
}

.progressNumber {
  margin-right: 1rem;
  font-family: var(--font-roboto);
  font-size: 0.8rem;
  user-select: none;
}

.resetButton {
  opacity: 0.25;

  &:hover {
    opacity: 1;
  }
}

.checkbox {
  opacity: 0.25;

  &:hover {
    opacity: 1;
  }
}

.checkboxLabel {
  label {
    span {
      font-size: 0.9rem;
    }
  }
}

.editorBottomReady {
  background: var(--green-ready);
}

.editorBottomIgnore {
  background: var(--red-not-ready);
}

.stepper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  margin-bottom: 4rem;
  margin-top: 2rem;
}

.stepLabel {

  span {
    max-width: 350px;
    text-align: left;
    // background: red;
  }
}

.banner {
  font-family: var(--font-roboto);
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;

  .bannerLeft {
    width: 50%;
    padding: 0.5rem 0rem;
    display: flex;
    align-items: flex-end;

    h2 {
      margin-right: auto;
      // background: red;
      padding-right: 1rem;
      font-size: 0.8rem;
      // position: relative;

    }
    // h2::after {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   right: -1rem; /* Adjust this value to position the arrow correctly */
    //   width: 0;
    //   height: 0;
    //   border-top: 1rem solid transparent;
    //   border-bottom: 1rem solid transparent;
    //   // border-left: 1rem solid red;
    // }

    .minuteRead {
      opacity: 0.5;
      font-size: 0.8rem;
      margin-right: 1rem;
    }

    .words {
      margin-right: 0.5rem;
      opacity: 0.5;
      font-size: 0.8rem;
    }

    .stats {
      opacity: 0.5;
      font-size: 0.8rem;
      margin-right: 0.5rem;
    }

    .splitter {
      margin-right: 0.5rem;
      opacity: 0.5;
      font-size: 0.8rem;
    }
  }

  .bannerRight {
    width: 50%;
    padding: 0.5rem 0rem;
    display: flex;
    align-items: flex-end;

    h2 {
      margin-right: auto;
      font-size: 0.8rem;
    }

    .stats {
      margin-right: 1rem;
      opacity: 0.5;
      font-size: 0.8rem;
    }
  }
}

.breadCrumb {
  padding-bottom: 1rem;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;

  display: flex;
}

.title {
  font-size: 0.9rem;
  font-family: var(--font-roboto);
  background: var(--hover-menu-bg);
  border-bottom: 2px solid var(--faint-border-color);
  padding: 0.5rem;
  border-radius: 3px;
  font-weight: bold;

  span {
    opacity: 0.45;
  }
}

.loader {
  // vertical align center vh of page
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 10rem;
}

.rightSideWrapper {
  margin-left: auto;
}
