.headerWrapper {
  background: #FFF;
  padding: 24px;
  color: var(--primary-font-rgb);
  font-family: var(--font-mono);
}

.header {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.logo {
  max-width: 640px;
  transition: opacity 0.3s ease-in-out;
}

.logo:hover {
  opacity: 0.9;
}

.logoImage {
  width: calc(140.7084px * 4.5);
  height: calc(55.6354px * 4.5);
}

@media screen and (max-width: 550px) {
  .headerWrapper {
    padding: 0;
    width: 100%;
  }

  .logoImage {
    width: calc(140.7084px * 2.5);
    height: calc(55.6354px * 2.5);
    padding-top: 24px;
  }

  .logo {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 1600px) {
  .headerWrapper {
    max-width: var(--max-width);
    margin-left: auto;
    margin-right: auto;
  }
}

