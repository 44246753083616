@keyframes slideUp {
  from {
    bottom: -175px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 0.9;
  }
}

.wrapper {
  color: #757575;
  background: #FFF;
  position: absolute;
  bottom: -175px;
  width: 100vw;
  z-index: 1000;
  height: 175px;
  padding: 16px;
  opacity: 0.9;
  overflow-y: scroll;
  animation-name: slideUp;  /* apply the animation */
  animation-duration: 0.3s;  /* make it last 2 seconds */
  animation-delay: 2s;  /* start after 2 seconds */
  animation-fill-mode: forwards;  /* make the div stay at the end state after the animation ends */
  animation-timing-function: ease-in;  /* ease in */
}

.consentWrap {
  margin-right: auto;
  margin-left: auto;
  /* display: flex;
  flex-wrap: wrap; */
  max-width: 1200px;
}

.titleWrap {
  display: flex;
}

.titleWrap h2 {
  cursor: text;
  margin-bottom: 10px;
}

.moreOptions {
  margin-left: auto;
}

.textBlurb {
  cursor: text;
  width: 100%;
  font-size: 0.8em;
}

.buttonConsent {
  background: #FFF;
  border: 1px solid #000;
  padding: 8px;
  margin: 8px;
  cursor: pointer;
  font-size: 0.8em;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  width: 175px;
  text-align: center;
  margin-left: auto;
  border-radius: 2px;
  transition: all 0.25s ease-out;
}

.buttonConsent:hover {
  border-color: rgb(199,141,63);
  color: rgb(199,141,63);
}

.moreOptions {
  text-align: center;
  max-height: 50px;
  margin-right: 8px;
  cursor: pointer;
}

.iconButton {
  background: rgba(221, 221, 221, 0.9);
  padding: 5px 10px;
  border-radius: 10px;
  transition: all 0.25s ease-out;
}

.iconButton:hover {
  background: rgba(221, 221, 221, 0.6);
}

.cookieWrapper {
  max-height: 200px;
  overflow-y: scroll;
}

.cookieOpt {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 8px;
  min-width: 300px;
  text-align: left;
}

.cookieLabel {
  /* background: red; */
}

.cookieDetail {
  font-size: 0.8em;
  max-width: 400px;
  opacity: 0.9;
}

.toggle {
  margin-right: 16px;
}

/* when width is under 500 pixels wide */

@media (max-width: 500px) {
  .wrapper {
    height: 50%;
    width: 100vw;
    bottom: 0;
    overflow-y: scroll;
    opacity: 1;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  /* .moreOptions {
    margin-left: 0;
    margin-top: 8px;
  } */

  .buttonConsent {
    margin-left: auto;
    margin-right: auto;
  }

  .cookieLabel {
    margin-bottom: 8px;
  }

  .cookieDetail {
    max-width: 200px;
    opacity: 1;
  }

  .textBlurb {
    padding: 16px;
    font-size: 1em;
  }

  .customDialog > div > div {
    margin: 5px;
  }

  @keyframes slideUp {
    from {
      bottom: -175px;
      opacity: 0;
    }
    to {
      bottom: 0;
      opacity: 1;
    }
  }
}
