.wrapper {
  background: var(--background-color);

  // * {
  //   outline: 1px solid red;
  //   outline-offset: -1px;
  // }
}

.innerWrapper {
  padding: 2rem;
  background: var(--background-color);
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh;
  width: 100%;
}

.buttonWrapper {
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--faint-border-color);
  padding-left: 17px;
  padding-right: 17px;
  display: flex;
  flex-wrap: wrap;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 0.7; }
}

@keyframes fadeOut {
  0% { opacity: 0.7; }
  50% { opacity: 0.7; }
  100% { opacity: 0; }
}

.saveStatus {
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 17px;
  font-size: 0.9rem;
  opacity: 0.7;
  font-family: var(--font-mono);
}

.saveStatus.fadeIn {
  opacity: 0;
  animation: fadeIn 2s;
}

.doneStatus {
  opacity: 0;
  animation: fadeOut 1.25s;
}
