.outerWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.pageWrapper {
  display: flex;
  margin-right: auto;
}

.perPageWrapper {
  margin-left: auto;
  text-align: right;
}

.perPageInnerWrapper {
  display: flex;
  margin-left: auto;
}

.perPageMsg {
  margin-left: 10px;
  padding-top: 4px;
}

.forwardBack {
  border: 1px solid var(--primary-font-rgb);
  padding: 4px 12px;
  font-size: 13px;
  margin-left: 3.2px;
  margin-right: 3.2px;
  color: var(--primary-font-rgb);
  border-radius: 2px;
  cursor: pointer;
  margin-bottom: 16px;
  transition: background 0.3s ease, color 0.3s ease;
}

.forwardBack:hover {
  background: var(--primary-color);
  color: var(--background-color);
}

.dot {
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 8px;
}

.selected {
  border: 1px solid var(--primary-font-rgb);
  padding: 4px 8px;
  font-size: 13px;
  margin-left: 3.2px;
  margin-right: 3.2px;
  background: var(--primary-color);
  color: var(--background-color);
  border-radius: 2px;
  margin-bottom: 16px;
  transition: background 0.3s ease, color 0.3s ease;
}

.unselected {
  border: 1px solid var(--primary-font-rgb);
  padding: 4px 8px;
  font-size: 13px;
  margin-left: 3.2px;
  margin-right: 3.2px;
  color: var(--primary-font-rgb);
  border-radius: 2px;
  cursor: pointer;
  margin-bottom: 16px;
  transition: background 0.3s ease, color 0.3s ease;
}

.unselected:hover {
  background: var(--primary-color);
  color: var(--background-color);
}


.marginLeftAuto {
  margin-left: auto;
}


@media (max-width: 450px) {
  .outerWrapper {
    flex-direction: column !important;
  }

  .perPageWrapper {
    margin-left: 0px;
  }
}

@media (max-width: 730px) {
  .outerWrapper {
    flex-direction: column-reverse;
  }

  .perPageWrapper {
    margin-bottom: 45px;
    text-align: center;
    margin-right: auto;
  }

  .pageWrapper {
    flex-wrap: wrap;
  }
}
