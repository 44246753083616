.shimmerWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  flex-direction: row;
  margin-bottom: 2rem;
  justify-content: center;
}

.shimmer {
  padding: 0.5rem;
  border-radius: 2px;
  width: 400px;
  text-align: center;
  box-shadow: var(--default-box-shadow);
  margin-bottom: 2rem;
}
