.shimmerWrapper {
  margin-bottom: 2rem;
}

.shimmerRow {
  margin-bottom: 2rem;
}

.shimmer {
  padding: 0.5rem;
  border-radius: 2px;
  width: 100%;
  text-align: center;
  display: flex;
}

.left {
  width: calc(100% - 200px);
  margin-right: 1rem;
}
