.main {
  background: #E040FB;
}

.context {
  width: 100%;
  position: absolute;
  z-index: 1000;
}

.context > .titleWrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
}

.context > .titleWrapper > h1 {
  cursor: text;
  color: #fff;
  font-size: 50px;
  margin-bottom: 1em;
  font-family: var(--font-mono);
  margin-top: 5vh;
}

.context > .details {
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  color: #FFF;
  font-size: 20px;
  font-family: var(--font-mono);
  margin-bottom: 4em;
}

.context > .details > p {
  margin-bottom: 1em;
  line-height: 1em;
}

.segway {
  background: #FFF;
  padding: 2em 4em;
}

.innerSegway {
  margin-left: auto;
  margin-right: auto;
  max-width: 740px;
}

.innerSegway > p {
  line-height: 3em;
  text-align: center;
}

.outerArticle {
  background: var(--background-color);
  color: var(--primary-font-rgb);
}

.innerArticle {
  margin-left: auto;
  margin-right: auto;
  max-width: 740px;
  font-family: var(--font-roboto);
}

.innerArticle > h1 {
  font-size: 2em;
  // margin-bottom: 1em;
  font-weight: 700;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0;
  font-family: var(--font-roboto);
}

.innerArticle > h2 {
  font-size: 1.75em;
  // margin-bottom: 1em;
  margin-top: 1em;
}

.innerArticle > h3 {
  font-size: 1.5em;
  // margin-bottom: 1em;
  margin-top: 1em;
}

.innerArticle > h5 {
  margin-bottom: 2em;
}

.innerArticle > hr {
  margin-bottom: 1em;
}

.innerArticle > p {
  font-size: 21px;
  font-style: normal;
  margin-bottom: 1em;
  line-height: 1.58;
  font-weight: 400;
  letter-spacing: -.003em;
}

.imageContainer {
  background: #FFF;
  margin-bottom: 2em;
  margin-left: auto;
  margin-right: auto;
  display: block;
  max-width: 736px;
}

.innerArticle a {
  color: #FFAB00;
  text-decoration: none;
  font-weight: bold;
  border-bottom: 1px solid #FFAB00;
}

.wrapper {
  padding: 2rem 0px;
  padding-bottom: 10em;

  h1 {
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
  }
  ul {
    margin-left: 1rem;
    margin-bottom: 2rem;
    li {
      margin-bottom: 0.5rem;
      ul {
        margin-top: 0.5rem;
        margin-left: 2rem;
      }
    }
  }

  h2 {
    margin-bottom: 1rem;
  }

  h3 {
    margin-bottom: 1rem;
  }

  h4 {
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
  }
}

.header {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1rem;
}

@media (max-width: 800px) {
  .header {
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 0px;
  }
}

@media (max-width: 450px) {
  .context {
    padding: 16px;
    overflow: hidden;
  }

  .context p {
    width: calc(100% - 32px);
  }

  .wrapper {
    padding: 2rem 1rem;
  }
}
