.title {
  span {
    text-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }
}

.subtitle {
  span {
    text-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }
}

.description {
  span {
    text-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }
}

// lowest to highest media screen
@media screen and (max-width: 450px) {
  .backgroundImage {
    height: 900px;

    img {
      height: 900px !important;
      width: auto !important;
    }
  }

  .phoneMockup {
    top: 17rem !important;
    width: auto !important;
    left: 0 !important;

    img {
      height: 700px !important;
      width: auto !important;
      transform: translate(-30%);
    }
  }

  .title {
    font-size: 3.5rem !important;
  }

  .subtitle {
    font-size: 1.8rem !important;
  }

  .description {
    font-size: 1.5rem !important;
  }
}

@media screen and (max-width: 500px) {
  .backgroundImage {
    img {
      height: 900px !important;
      width: auto !important;
    }
  }

  .phoneMockup {
    top: 20rem !important;
    width: auto !important;
    left: 0 !important;

    img {
      height: 700px !important;
      width: auto !important;
      // transform: translate(-25%);
    }
  }

  .title {
    font-size: 3.5rem !important;
  }

  .subtitle {
    font-size: 1.8rem !important;
  }

  .description {
    font-size: 1.5rem !important;
  }
}

@media screen and (min-width: 500px) and (max-width: 600px) {
  .title {
    font-size: 3.5rem !important;
  }

  .subtitle {
    font-size: 1.8rem !important;
  }

  .description {
    font-size: 1.5rem !important;
  }
}


@media screen and (min-width: 500px) and (max-width: 800px) {
  .backgroundImage {
    img {
      height: 900px !important;
      width: auto !important;
    }
  }

  .phoneMockup {
    // top: 20rem !important;
    // width: auto !important;
    // left: 0 !important;
    // display: flex !important;
    // justify-content: center !important; /* Horizontally center the content */
    // align-items: center !important; /* Vertically center the content (if needed) */
    // height: 100%;

    // img {
    //   height: 700px !important;
    //   width: auto !important;
    // }
  }
}


@media screen and (min-width: 800px) and (max-width: 1080px) {
  .backgroundImage {
    img {
      height: 900px !important;
      width: auto !important;
    }
  }

  .phoneMockup {
    top: 18rem !important;

    // img {
    //   height: 900px !important;
    //   width: auto !important;
    //   transform: translate(-10%);
    // }
  }
}
