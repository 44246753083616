.wrapper {
  // margin-top: 1rem;
  padding: 1rem;
  border: 1px solid var(--faint-border-color);
  font-family: var(--font-roboto);
  border-radius: 6px;
}

.wrapperLoading {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.buttonSection {
  display: flex;
}

.dropdownButton {
  margin-left: auto;
}

.title {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 7px;
}

.description {
  font-size: 0.9rem;
}

.light {
  opacity: 0.75;
}

.dropdownButton {
  display: flex;
  align-items: center;
}

.inputWrapper {
  margin-top: 1.2rem;
}

.datePicker {
  margin-right: 1rem !important;
}

.pickers {
  margin-bottom: 1rem;
}

.timezoneWrapper {
  width: 233px;
  margin-right: 1rem;
}

.resetButton {
  display: flex;
  align-items: center;
  // margin-left: auto;
  width: 200px;
}

@media screen and (max-width: 450px) {
  .datePicker {
    margin-bottom: 1rem !important;
  }
}
