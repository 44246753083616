.tag {
  // background-color: #f0f0f0;
  padding: 0.5rem;
  border-radius: 2px;
  cursor: pointer;
  width: 400px;
  text-align: center;
  box-shadow: var(--default-box-shadow);
  margin-bottom: 2rem;
}

.tagName {
  font-size: 1.5rem;
  padding: 0.75rem 0.5rem;;
  // font-weight: bold;
}
