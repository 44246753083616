.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 70px 1rem;
  text-align: center;

  h3 {
    font-size: 2.5rem;
    font-weight: 100;
    margin-bottom: 2rem;
    max-width: 700px;
  }
}

.list {
  max-width: 500px;
  text-align: left;
}

.listItem {
  display: flex;
  font-size: 1.25rem;
  margin-bottom: 2rem;

  div {
    margin-right: 2.5rem;
    // vertical align ele
    display: flex;
    align-items: center;

    svg {
      color: var(--secondary-font-rgb);
    }
  }

  p {
    line-height: 1.5;
  }
}

.link {
  font-size: 1.5rem;
  font-weight: 100;
  color: var(--secondary-font-rgb);
  text-decoration: none;
  position: relative;
  transition: all 0.25s ease-in-out;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px; // Adjust the thickness of the underline
    bottom: 0;
    left: 50%;
    background-color: currentColor; // Use the text color for the underline
    transition: all 0.25s ease-in-out;
  }

  &:hover::after {
    width: 100%;
    left: 0;
  }
}

@media screen and (max-width: 500px) {
  .listItem {
    div {
      margin-right: 1.5rem;
    }
  }
}
